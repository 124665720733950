import React from 'react';
import styled from 'styled-components';

// import SVG from '../../assets/images/gatsby-storybook.svg';

const Img = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;
`;

const Image = () => <Img src="./logo.png" alt="GBH" />;

export default Image;
